<template>
    <uw-content title="我的合同" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button :type="search.workflow_state == null ? 'primary' : 'text'" @click="search.workflow_state = null; TableSearch()">全部</el-button>
            <el-button :type="search.workflow_state == 1 ? 'primary' : 'text'" @click="search.workflow_state = 1; TableSearch()">待审批</el-button>
            <el-button :type="search.workflow_state == 2 ? 'primary' : 'text'" @click="search.workflow_state = 2; TableSearch()">审批中</el-button>
            <el-button :type="search.workflow_state == 4 ? 'primary' : 'text'" @click="search.workflow_state = 4; TableSearch()">已通过</el-button>
            <el-button :type="search.workflow_state == 3 ? 'danger' : 'text'" @click="search.workflow_state = 3; TableSearch()">已拒绝</el-button>
            <el-button :type="search.workflow_state == 5 ? 'primary' : 'text'" @click="search.workflow_state = 5; TableSearch()">待撤销</el-button>
            <el-button :type="search.workflow_state == 6 ? 'danger' : 'text'" @click="search.workflow_state = 6; TableSearch()">撤销中</el-button>
            <el-button :type="search.workflow_state == 7 ? 'primary' : 'text'" @click="search.workflow_state = 7; TableSearch()">撤销失败</el-button>
            <el-button :type="search.workflow_state == 8 ? 'danger' : 'text'" @click="search.workflow_state = 8; TableSearch()">撤销成功</el-button>
            <el-button :type="search.workflow_state == 0 ? 'warning' : 'text'" @click="search.workflow_state = 0; TableSearch()">无需审核</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <files-export-xls-dropdown model="crm_contract" :search="search" :page="page" :style="{ marginRight: '5px' }" />
            <el-button type="primary" @click="$refs.contractCreate.Open()">新的合同</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 合同编号&合同名称 -->
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.number" clearable  size="mini" placeholder="请输入合同编号"></el-input>
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.name" clearable  size="mini" placeholder="请输入合同名称"></el-input>

                <!-- 客户 -->
                <crm-customer-select v-model="search.customer_id" @onChange="TableSearch" clearable />

                <!-- 合同状态&审核状态 -->
                <contract-state-select v-model="search.state" @onChange="TableSearch" />

                <el-date-picker v-model="search.sign_date" clearable @change="TableSearch" value-format="yyyy-MM-dd"  size="mini" type="daterange" range-separator="至" start-placeholder="签单日期查询" end-placeholder="查询范围"></el-date-picker>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="contract"
                    :loading="loading"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">
                    
                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 合同编号 -->
                    <vxe-column min-width="100" field="number" title="合同编号" sortable>
                        <template #default="{ row }">
                            <el-link @click="$refs.drawerContractDetails.Open(row.id)" type="primary">{{ row.number }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 合同名称 -->
                    <vxe-column min-width="250" field="name" title="合同名称" :visible="false"></vxe-column>
                    <vxe-column min-width="300" field="name" title="主题" sortable>
                        <template #default="{ row }">
                            <!-- 审批判断 -->
                            <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">无审核</el-tag>
                            <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                            <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                            <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                            <el-tag v-if="row.workflow_state >= 4" size="mini" effect="dark" type="success">已通过</el-tag>
                            <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                            <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                            <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">拒绝撤销</el-tag>
                            <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="warning">已撤销</el-tag>
                            <!-- 草稿判断 -->
                            <el-tag v-if="row.project_id" size="mini" effect="plain" class="w-margin-l-5">项</el-tag>
                            <el-tag v-if="row.files_id.length > 0" size="mini" effect="plain" type="success" class="w-margin-l-5">附</el-tag>
                            <el-tag v-if="row.draft" size="mini" effect="plain" type="warning" class="w-margin-l-5">稿</el-tag>
                            <!-- 项目名称 -->
                            {{ row.name }}
                        </template>
                    </vxe-column>

                    <!-- 客户单位 -->
                    <vxe-column min-width="250" field="customer.name" title="客户单位"></vxe-column>

                    <!-- 预估成本 -->
                    <vxe-column width="140" field="estimate_money" title="预估成本/￥" sortable>
                        <template #default="{ row }">
                            <el-link type="warning">{{ row.estimate_money.toLocaleString() }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 签单金额 -->
                    <vxe-column width="140" field="sign_money" title="签单金额/￥" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">{{ row.sign_money.toLocaleString() }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 签单日期 -->
                    <vxe-column width="160" field="sign_date" title="签单日期" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-time">{{ row.sign_date }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 团队 -->
                    <vxe-column width="90" title="销售">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.users_sale.name }}</el-link>
                        </template>
                    </vxe-column>
                    <vxe-column width="90" title="商务">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.users_affair.name }}</el-link>
                        </template>
                    </vxe-column>
                    <vxe-column width="90" title="售前">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.users_presale.name }}</el-link>
                        </template>
                    </vxe-column>
                    <vxe-column width="90" title="管理员">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.users_admin.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 合同状态 -->
                    <vxe-column width="160" field="state" title="合同状态" sortable></vxe-column>
                    
                    <!-- 更新日期 -->
                    <vxe-column width="160" field="updated_at" title="更新日期" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-time">{{ row.updated_at }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 创建日期 -->
                    <vxe-column width="160" field="created_at" title="创建日期" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-time">{{ row.created_at }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="80"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link @click.native.stop="ContractDelete(row.id)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <crm-contract-create ref="contractCreate" @onSave="TableSearch()" />
        <crm-contract-drawer ref="drawerContractDetails" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
                name: null,
                state: null,
                workflow_state: null,
                customer_id: null,
                sign_date: null,
                auth_employee: true,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            // 字段管理
            column: {
                append: ['team', 'customer'],
                hidden: ['describe'],
            },

            // 数据
            contract: [],
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 合同 - 检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/crm/contract/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['customer', 'users_sale', 'users_admin', 'users_affair', 'users_presale'],
                    hidden: ['describe']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.contract = rps.data.body

                // 页码溢出
                if (this.contract.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },


        /**
        |--------------------------------------------------
        | 合同 - 删除
        |--------------------------------------------------
        | *id (int) 合同ID
        |
        */
        ContractDelete(id) {
            this.$http.post('/9api/contract/delete', {id: id}).then(rps => {
                if (rps.data.state == 'success' ) {
                    this.TableSearch()
                }
            })
        },
    }
}
</script>